export const selectFacetFilterOptions = [
  {
    label: "Administration Method",
    id: "administration_information.administration_method",
    cachedFacetType: "administration_information.administration_method",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "Administration Information",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "Alternate Names",
    id: "active_compound.alternate_names.keyword",
    cachedFacetType: "",
    type: "TextInput",
    isBooleanFilter: false,
    recordCategory: "Active Compound",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: false,
  },
  {
    label: "ATC Code",
    id: "atcCodeFilter",
    cachedFacetType: "hss_atc",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "General",
    filterRequestStructure: "basicSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "Brand Name",
    id: "active_compound.possible_brand_names.keyword",
    cachedFacetType: "",
    type: "TextInput",
    isBooleanFilter: false,
    recordCategory: "Active Compound",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: false,
  },
  {
    label: "CAS Numbers",
    id: "active_compound.cas_numbers",
    cachedFacetType: "",
    type: "TextInput",
    isBooleanFilter: false,
    recordCategory: "Active Compound",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: false,
  },
  {
    label: "Company",
    id: "organizationFilter",
    cachedFacetType: "organization",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "General",
    filterRequestStructure: "basicSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "EU - Accelerated Assessment",
    id: "europeanMedicinesAgency.accelerated_assessment",
    cachedFacetType: "europeanMedicinesAgency.accelerated_assessment",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "European Union (EU)",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "EU - ATMP",
    id: "europeanMedicinesAgency.atmp_status",
    cachedFacetType: "europeanMedicinesAgency.atmp_status",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "European Union (EU)",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "EU - Agency Product Number",
    id: "europeanMedicinesAgency.agency_product_number",
    cachedFacetType: "",
    type: "TextInput",
    isBooleanFilter: false,
    recordCategory: "European Union (EU)",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: false,
  },
  // {
  //   label: "EU - Designation Type",
  //   id: "europeanMedicinesAgency.designation.designation_ema",
  //   cachedFacetType: "europeanmedicinesagency.designation.designation_ema",
  //   type: "AutoComplete",
  //   isBooleanFilter: false,
  //   recordCategory: "European Union (EU)",
  //   filterRequestStructure: "advanceSearchFilter",
  //   orClauseEnabled: true,
  // },
  {
    label: "EU - EC Decision (Estimated)",
    id: "emaEventsByDateRangeAndEventNameFilter",
    cachedFacetType: "",
    type: "DatePicker",
    isBooleanFilter: false,
    recordCategory: "European Union (EU)",
    filterRequestStructure: "basicSearchFilter",
    orClauseEnabled: false,
  },
  {
    label: "EU - EC Decision",
    id: "emaEventsByDateRangeAndEventNameFilter",
    cachedFacetType: "",
    type: "DatePicker",
    isBooleanFilter: false,
    recordCategory: "European Union (EU)",
    filterRequestStructure: "basicSearchFilter",
    orClauseEnabled: false,
  },
  {
    label: "EU - MAA Submission (Estimated)",
    id: "emaEventsByDateRangeAndEventNameFilter",
    cachedFacetType: "",
    type: "DatePicker",
    isBooleanFilter: false,
    recordCategory: "European Union (EU)",
    filterRequestStructure: "basicSearchFilter",
    orClauseEnabled: false,
  },
  {
    label: "EU - MAA Submission",
    id: "emaEventsByDateRangeAndEventNameFilter",
    cachedFacetType: "",
    type: "DatePicker",
    isBooleanFilter: false,
    recordCategory: "European Union (EU)",
    filterRequestStructure: "basicSearchFilter",
    orClauseEnabled: false,
  },
  {
    label: "EU - CHMP Opinion (Estimated)",
    id: "emaEventsByDateRangeAndEventNameFilter",
    cachedFacetType: "",
    type: "DatePicker",
    isBooleanFilter: false,
    recordCategory: "European Union (EU)",
    filterRequestStructure: "basicSearchFilter",
    orClauseEnabled: false,
  },
  {
    label: "EU - CHMP Opinion",
    id: "emaEventsByDateRangeAndEventNameFilter",
    cachedFacetType: "",
    type: "DatePicker",
    isBooleanFilter: false,
    recordCategory: "European Union (EU)",
    filterRequestStructure: "basicSearchFilter",
    orClauseEnabled: false,
  },
  {
    label: "EU - License Extension - Pharmaceutical Form Change",
    id: "europeanMedicinesAgency.pharmaceutical_form_change",
    cachedFacetType: "",
    type: "AutoComplete",
    isBooleanFilter: true,
    recordCategory: "Active Compound",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "EU - License Extension - Route of Administration Change",
    id: "europeanMedicinesAgency.route_of_administration_change",
    cachedFacetType: "",
    type: "AutoComplete",
    isBooleanFilter: true,
    recordCategory: "Active Compound",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "EU - License Extension - Strength Change",
    id: "europeanMedicinesAgency.strength_change",
    cachedFacetType: "",
    type: "AutoComplete",
    isBooleanFilter: true,
    recordCategory: "Active Compound",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "EU - New Indication",
    id: "europeanMedicinesAgency.new_indication",
    cachedFacetType: "",
    type: "AutoComplete",
    isBooleanFilter: true,
    recordCategory: "Active Compound",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "EU - Orphan Designation",
    id: "europeanMedicinesAgency.orphan_designation",
    cachedFacetType: "europeanMedicinesAgency.orphan_designation",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "Active Compound",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "EU - Open",
    id: "europeanMedicinesAgency.open_status",
    cachedFacetType: "europeanMedicinesAgency.open_status",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "Active Compound",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "EU - Product Number",
    id: "europeanMedicinesAgency.eu_product_number",
    cachedFacetType: "",
    type: "TextInput",
    isBooleanFilter: false,
    recordCategory: "Active Compound",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: false,
  },
  {
    label: "EU - PUMA",
    id: "europeanMedicinesAgency.puma_status",
    cachedFacetType: "europeanMedicinesAgency.puma_status",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "Active Compound",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "EU - Regulatory Status",
    id: "emaRegulatoryStatusFilter",
    cachedFacetType: "ema.regulatory_status",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "European Union (EU)",
    filterRequestStructure: "basicSearchFilter",
    orClauseEnabled: true,
  },
  // {
  //   label: "EU - Start of Evaluation",
  //   id: "emaEventsByDateRangeAndEventNameFilter",
  //   cachedFacetType: "",
  //   type: "DatePicker",
  //   isBooleanFilter: false,
  //   recordCategory: "European Union (EU)",
  //   filterRequestStructure: "basicSearchFilter",
  //   orClauseEnabled: false,
  // },
  {
    label: "Estimated Cost",
    id: "hss_estimated_cost.keyword",
    cachedFacetType: "hss_estimated_cost",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "General",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "Estimated Indication",
    id: "estimated_indication.estimated_indication",
    cachedFacetType: "estimated_indication",
    type: "TextInput",
    isBooleanFilter: false,
    recordCategory: "General",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: false,
  },
  // {
  //   label: "Expected EC Authorisation Date",
  //   id: "expected_authorization_date.keyword",
  //   cachedFacetType: "expected_authorization_date.keyword",
  //   type: "AutoComplete",
  //   isBooleanFilter: false,
  //   recordCategory: "General",
  //   filterRequestStructure: "advanceSearchFilter",
  //   orClauseEnabled: true,
  // },
  {
    label: "FDA - Designation",
    id: "fda.designation.designation_fda.keyword",
    cachedFacetType: "fda.designation.designation_fda",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "US Food & Drug Administration (FDA)",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "FDA - Regulatory Status",
    id: "fdaRegulatoryStatusFilter",
    cachedFacetType: "fda.regulatory_status",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "US Food & Drug Administration (FDA)",
    filterRequestStructure: "basicSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "First in Class",
    id: "classification_and_codes.first_in_class",
    cachedFacetType: "",
    type: "AutoComplete",
    isBooleanFilter: true,
    recordCategory: "Classification and Codes",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "Granted Prime Eligibility",
    id: "europeanMedicinesAgency.granted_prime_eligibility",
    cachedFacetType: "europeanMedicinesAgency.granted_prime_eligibility",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "European Union (EU)",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "High Impact Report",
    id: "hss_hir",
    cachedFacetType: "",
    type: "AutoComplete",
    isBooleanFilter: true,
    recordCategory: "General",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "IHSI Category",
    id: "ihsiCategoryFilter",
    cachedFacetType: "ihsi_category",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "General",
    filterRequestStructure: "basicSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "IHSI Price Tier",
    id: "cost.ihsi_price_tier",
    cachedFacetType: "cost.ihsi_price_tier",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "Analysis",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "INN",
    id: "active_compound.inn.keyword",
    cachedFacetType: "",
    type: "TextInput",
    isBooleanFilter: false,
    recordCategory: "Active Compound",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: false,
  },
  {
    label: "Intended Site Broad",
    id: "administration_information.intended_site_broad",
    cachedFacetType: "administration_information.intended_site_broad",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "Administration Information",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "Intended Use",
    id: "administration_information.intended_use.keyword",
    cachedFacetType: "administration_information.intended_use",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "Administration Information",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "Last Updated",
    id: "lastUpdatedFilter",
    cachedFacetType: "",
    type: "DatePicker",
    isBooleanFilter: false,
    recordCategory: "General",
    filterRequestStructure: "basicSearchFilter",
    orClauseEnabled: false,
  },
  {
    label: "Mechanism of Action",
    id: "active_compound.mechanism_of_action",
    cachedFacetType: "",
    type: "TextInput",
    isBooleanFilter: false,
    recordCategory: "Active Compound",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: false,
  },
  {
    label: "MedDRA - Lowest Level Terms (LLT)",
    id: "classification_and_codes.meddra_llt_names_and_codes.name.keyword",
    cachedFacetType: "classification_and_codes.meddra_llt_names_and_codes.keyword",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "General",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "MedDRA",
    id: "classification_and_codes.meddra_pt.name.keyword",
    cachedFacetType: "classification_and_codes.meddra_pt",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "General",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "MedDRA - System Organ Class",
    id: "classification_and_codes.meddra_soc.name.keyword",
    cachedFacetType: "classification_and_codes.meddra_soc",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "General",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "MedDRA - Highest Group Level Terms",
    id: "classification_and_codes.meddra_hlgt.name.keyword",
    cachedFacetType: "classification_and_codes.meddra_hlgt",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "General",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "MedDRA - Hierarchy",
    id: "meddra_hierarchy",
    cachedFacetType: "meddra_hierarchy",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "General",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "Monotherapy",
    id: "administration_information.monotherapy",
    cachedFacetType: "administration_information.monotherapy",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "Administration Information",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "Originator/Generic/Biosimilar",
    id: "classification_and_codes.generic_or_biosimilar",
    cachedFacetType: "classification_and_codes.generic_or_biosimilar",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "Classification and Codes",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "Partner Companies",
    id: "partner_companies.keyword",
    cachedFacetType: "partner_companies",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "General",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "Pharmaceutical Form",
    id: "basicFormFilter",
    cachedFacetType: "basicform",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "General",
    filterRequestStructure: "basicSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "Potential ATMP",
    id: "classification_and_codes.potential_atmp",
    cachedFacetType: "",
    type: "AutoComplete",
    isBooleanFilter: true,
    recordCategory: "Classification and Codes",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "Place in Treatment",
    id: "administration_information.line_of_therapy.LineOfTherapy.keyword",
    cachedFacetType: "administration_information.line_of_therapy.LineOfTherapy",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "Administration Information",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "Record Status",
    id: "recordStatusFilter",
    cachedFacetType: "record_status",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "General",
    filterRequestStructure: "basicSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "Relation to Standard of Care",
    id: "cost.relation_to_standard_of_care",
    cachedFacetType: "cost.relation_to_standard_of_care",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "Analysis",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "Release Characteristics",
    id: "administration_information.release_characteristics",
    cachedFacetType: "administration_information.release_characteristics",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "Administration Information",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "Substance Name",
    id: "compoundFilter",
    cachedFacetType: "compound",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "General",
    filterRequestStructure: "basicSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "Transformation",
    id: "administration_information.transformation.keyword",
    cachedFacetType: "administration_information.transformation",
    type: "AutoComplete",
    isBooleanFilter: false,
    recordCategory: "Administration Information",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: true,
  },
  {
    label: "USAN",
    id: "active_compound.usan.keyword",
    cachedFacetType: "",
    type: "TextInput",
    isBooleanFilter: false,
    recordCategory: "Active Compound",
    filterRequestStructure: "advanceSearchFilter",
    orClauseEnabled: false,
  },
];
export const sanitizeSearchRequest = (request) => {
  let tempRequestObject = { ...request };
  if (tempRequestObject.advanceSearchFilter !== undefined) {
    tempRequestObject.advanceSearchFilter = tempRequestObject.advanceSearchFilter.map((item, index) => {
      delete item.masterArrayId;
      delete item.chosenFilterType;
      delete item.fieldLabel;
      delete item.isBooleanFilter;
      if (typeof item.fieldValue === "boolean") {
        item.fieldValue = item.fieldValue.toString();
      }
      return item;
    });
  }

  if (tempRequestObject.emaEventsByDateRangeAndEventNameFilter !== undefined) {
    tempRequestObject.emaEventsByDateRangeAndEventNameFilter =
      tempRequestObject.emaEventsByDateRangeAndEventNameFilter.map((item, index) => {
        delete item.masterArrayId;
        return item;
      });
  }

  selectFacetFilterOptions.forEach((item, index) => {
    if (tempRequestObject[item.id] !== undefined && tempRequestObject[item.id].length > 0) {
      tempRequestObject[item.id].forEach((item2, index2) => {
        delete item2.masterArrayId;
        delete item2.filterId;
        delete item2.count;
        delete item2.isBooleanFilter;
        if (item2?.label) {
          item2.title = item2.label;
          delete item2.label;
        }
      });
    }
  });
  delete tempRequestObject.isAdvancedSearch;
  delete tempRequestObject.emaTimelineId;
  delete tempRequestObject.lastUpdatedId;
  return tempRequestObject;
};

export const isWildcardSearch = () => {
  let currentSearchRequest = JSON.parse(localStorage.getItem("searchRequest"));
  if (Object.keys(currentSearchRequest).length <= 5 && currentSearchRequest.searchPhrase === "*") {
    return true;
  }
  return false;
};
