import React, { createContext, useContext, useReducer } from "react";
import { AlertsService } from "../services";
import alerts from "../components/Pages/PrivatePages/Dashboard/Releases/ListView/dummyData";
const AlertsState = createContext();
const AlertsDispatch = createContext();

const EVENT_TYPES = {
  UPDATE: "update",
  SUCCESS: "success",
  LOADING: "loading",
  CLEAR_ERRORS: "clear_errors",
  ERROR: "error",
};

const EVENTS = {
  [EVENT_TYPES.LOADING]: (state, event) => {
    return {
      ...state,
      alertsLoading: true,
      alertsSuccess: false,
      error: "",
    };
  },
  [EVENT_TYPES.SUCCESS]: (state, event) => {
    const { newData } = event.payload;

    return {
      ...state,
      alertsSuccess: true,
      alertsData: newData,
      alertsLoading: false,
    };
  },
  [EVENT_TYPES.ERROR]: (state, event) => {
    const { error } = event.payload;
    return {
      ...state,
      error,
      alertsSuccess: false,
      alertsLoading: false,
    };
  },
  [EVENT_TYPES.CLEAR_ERRORS]: (state) => {
    return {
      ...state,
      error: "",
      alertsSuccess: false,
      alertsLoading: false,
    };
  },
};

const INITIAL_STATE = {
  alertsSuccess: false,
  alertsLoading: false,
  alertsData: {},
  error: "",
};

const AlertsReducer = (state, event) => {
  return EVENTS[event.type](state, event) || state;
};

const AlertsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AlertsReducer, INITIAL_STATE);

  const handleGetAlerts = () => {
    /* REMOVE THIS - FOR TESTING ONLY */
    // dispatch({ type: EVENT_TYPES.LOADING });
    // setTimeout(() => {
    //   const newAlertsData = {
    //     alerts: alerts,
    //   };
    //   dispatch({ type: EVENT_TYPES.SUCCESS, payload: { newAlertsData } });
    //   return Promise.resolve();
    // }, 500);
    /*********************************/

    AlertsService.getAlerts()
      .then((data) => {
        setTimeout(() => {
          const newData = {
            alerts: data,
          };
          dispatch({ type: EVENT_TYPES.SUCCESS, payload: { newData } });
          return Promise.resolve();
        }, 500);
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ERROR,
          payload: {
            error: message,
          },
        });
      });
  };

  const handleError = (errorMsg) => {
    dispatch({
      type: EVENT_TYPES.ERROR,
      payload: {
        error: errorMsg,
      },
    });
  };
  const handleClearErrors = () => {
    dispatch({ type: EVENT_TYPES.CLEAR_ERRORS });
  };

  const events = {
    onError: handleError,
    onGetAlerts: handleGetAlerts,
    onClearErrors: handleClearErrors,
  };

  return (
    <AlertsState.Provider value={state}>
      <AlertsDispatch.Provider value={events}>{children}</AlertsDispatch.Provider>
    </AlertsState.Provider>
  );
};

const useAlertsState = () => {
  const context = useContext(AlertsState);

  if (context === undefined) {
    throw new Error("useAlertsState must be used within an AlertsProvider");
  }

  return context;
};

const useAlertsDispatch = () => {
  const context = useContext(AlertsDispatch);

  if (context === undefined) {
    throw new Error("useAlertsDispatch must be used within an AlertsProvider");
  }

  return context;
};

export { AlertsProvider, useAlertsState, useAlertsDispatch };
