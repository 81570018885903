const alerts = [
  {
    alertId: 1,
    alertType: ["Info", "Reminder"],
    title: "System Maintenance Alert",
    summary: "Scheduled maintenance on the system",
    content: "The system will be down for maintenance.",
    audienceRoles: ["Admin", "Manager"],
    severity: "High",
    tags: ["System", "Maintenance"],
    popupDuration: 30,
    notifyDate: "2024-11-12T10:00:00Z",
    expireDate: "2024-11-12T12:00:00Z",
    requestedBy: "system_admin",
    isEnabled: true,
    deleted: false,
    createdBy: "system_admin",
    createdDate: "2024-11-10T09:30:00Z",
    updatedBy: "system_admin",
    updatedDate: "2024-11-10T09:30:00Z",
  },
  {
    alertId: 2,
    alertType: ["Warning", "Update"],
    title: "Policy Update Alert",
    summary: "New policy changes effective immediately",
    content: "Please review the updated policies.",
    audienceRoles: ["Employee", "Supervisor"],
    severity: "Medium",
    tags: ["Policy", "Update"],
    popupDuration: 15,
    notifyDate: "2024-11-12T08:00:00Z",
    expireDate: "2024-11-20T23:59:59Z",
    requestedBy: "hr_admin",
    isEnabled: true,
    deleted: false,
    createdBy: "hr_admin",
    createdDate: "2024-11-10T09:35:00Z",
    updatedBy: "hr_admin",
    updatedDate: "2024-11-10T09:35:00Z",
  },
  {
    alertId: 3,
    alertType: ["Critical", "Alert"],
    title: "Emergency Security Alert",
    summary: "Security breach detected",
    content: "Immediate action required to secure data.",
    audienceRoles: ["Admin", "Security"],
    severity: "Critical",
    tags: ["Security", "Breach", "Urgent"],
    popupDuration: 60,
    notifyDate: "2024-11-11T14:00:00Z",
    expireDate: "2024-11-11T16:00:00Z",
    requestedBy: "security_team",
    isEnabled: true,
    deleted: false,
    createdBy: "security_team",
    createdDate: "2024-11-10T09:40:00Z",
    updatedBy: "security_team",
    updatedDate: "2024-11-10T09:40:00Z",
  },
  {
    alertId: 4,
    alertType: ["test update"],
    title: "test update",
    summary: "test update",
    content: "test update",
    audienceRoles: ["test update"],
    severity: "test update",
    tags: ["test update"],
    popupDuration: 100,
    notifyDate: "2024-11-11T21:32:48.535Z",
    expireDate: "2024-11-11T21:32:48.535Z",
    requestedBy: "someone",
    isEnabled: true,
    deleted: false,
    createdBy: "1",
    createdDate: "2024-11-11T21:29:39.49805Z",
    updatedBy: "1",
    updatedDate: "2024-11-11T22:01:05.09534Z",
  },
];

export default alerts;
