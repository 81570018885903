import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { hydrate, render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./App/scss/index.scss";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./providers/authProvider";
import { ExportProvider } from "./providers/exportProvider";
import { PrintProvider } from "./providers/printProvider";
import { HirProvider } from "./providers/hirProvider";
import { NotificationProvider } from "./providers/notificationProvider";
import { AccountSettingsProvider } from "./providers/accountSettingsProvider";
import { SaveSearchProvider } from "./providers/saveSearchProvider";
import { FaqProvider } from "./providers/faqProvider";
import { ManageFaqProvider } from "./providers/manageFaqProvider";
import { GlossaryProvider } from "./providers/glossaryProvider";
import { ManageGlossaryProvider } from "./providers/manageGlossaryProvider";
import { AlertsProvider } from "./providers/alertsProvider";
import { ManageAlertsProvider } from "./providers/manageAlertsProvider";
import { ManageUsersProvider } from "./providers/manageUsersProvider";

if (process.env.NODE_ENV !== "production") {
  localStorage.setItem("debug", "ihsi-ui:*");
}
const rootElement = document.getElementById("root");
// if (!rootElement) {
//   const newRootElement = document.createElement("div");
//   newRootElement.id = "root";
//   document.body.appendChild(newRootElement);
// }
if (rootElement.hasChildNodes()) {
  hydrate(
    <SaveSearchProvider>
      <AuthProvider>
        <ExportProvider>
          <PrintProvider>
            <HirProvider>
              <NotificationProvider>
                <AccountSettingsProvider>
                  <ManageUsersProvider>
                    <FaqProvider>
                      <ManageFaqProvider>
                        <GlossaryProvider>
                          <ManageGlossaryProvider>
                            <AlertsProvider>
                              <ManageAlertsProvider>
                                <Router>
                                  <App />
                                </Router>
                              </ManageAlertsProvider>
                            </AlertsProvider>
                          </ManageGlossaryProvider>
                        </GlossaryProvider>
                      </ManageFaqProvider>
                    </FaqProvider>
                  </ManageUsersProvider>
                </AccountSettingsProvider>
              </NotificationProvider>
            </HirProvider>
          </PrintProvider>
        </ExportProvider>
      </AuthProvider>
    </SaveSearchProvider>,
    rootElement
  );
} else {
  render(
    <SaveSearchProvider>
      <AuthProvider>
        <ExportProvider>
          <PrintProvider>
            <HirProvider>
              <NotificationProvider>
                <AccountSettingsProvider>
                  <ManageUsersProvider>
                    <FaqProvider>
                      <ManageFaqProvider>
                        <GlossaryProvider>
                          <ManageGlossaryProvider>
                            <AlertsProvider>
                              <ManageAlertsProvider>
                                <Router>
                                  <App />
                                </Router>
                              </ManageAlertsProvider>
                            </AlertsProvider>
                          </ManageGlossaryProvider>
                        </GlossaryProvider>
                      </ManageFaqProvider>
                    </FaqProvider>
                  </ManageUsersProvider>
                </AccountSettingsProvider>
              </NotificationProvider>
            </HirProvider>
          </PrintProvider>
        </ExportProvider>
      </AuthProvider>
    </SaveSearchProvider>,
    rootElement
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
