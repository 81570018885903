import React, { createContext, useContext, useReducer } from "react";
import ExportService from "../services/exportService";
import AuthService from "../services/authService";
import { isWildcardSearch } from "../utils/searchRequestHelpers";
const ExportState = createContext();
const ExportDispatch = createContext();

const EVENT_TYPES = {
  EXPORT_UPDATE: "export_update",
  EXPORT_UPDATE_SELECT_ALL: "export_update_select_all",
  EXPORT_UPDATE_CLEAR_ALL: "export_update_clear_all",
  EXPORT_LOADING: "export_loading",
  EXPORT_SUCCESS: "export_success",
  EXPORT_CLEAR_ERRORS: "export_clear_errors",
  EXPORT_ERROR: "export_error",
};

const EVENTS = {
  [EVENT_TYPES.EXPORT_UPDATE]: (state, event) => {
    return {
      ...state,
      exportItems: event.payload.itemsArray,
    };
  },
  [EVENT_TYPES.EXPORT_UPDATE_SELECT_ALL]: (state, event) => {
    return {
      ...state,
      exportSelectAll: event.payload.isSelectAll,
    };
  },
  [EVENT_TYPES.EXPORT_UPDATE_CLEAR_ALL]: (state, event) => {
    return {
      ...state,
      exportItems: [],
      exportSelectAll: false,
      exportSuccess: false,
      exportFile: "",
      exportLoading: false,
    };
  },
  [EVENT_TYPES.EXPORT_LOADING]: (state) => {
    return {
      ...state,
      exportLoading: true,
      exportSuccess: false,
    };
  },
  [EVENT_TYPES.EXPORT_SUCCESS]: (state, event) => {
    return {
      ...state,
      exportSuccess: true,
      exportLoading: false,
      exportFile: event.payload.exportData,
    };
  },
  [EVENT_TYPES.EXPORT_ERROR]: (state, event) => {
    const { exportError } = event.payload;
    return {
      ...state,
      exportError,
      exportSuccess: false,
      exportLoading: false,
    };
  },
  [EVENT_TYPES.EXPORT_CLEAR_ERRORS]: (state) => {
    return {
      ...state,
      exportError: "",
      exportSuccess: false,
      exportLoading: false,
    };
  },
};

const INITIAL_STATE = {
  exportItems: [],
  exportSelectAll: false,
  exportError: "",
  exportFile: "",
  componentType: "exportComponent",
  exportLoading: false,
  exportSuccess: false,
};

const ExportReducer = (state, event) => {
  return EVENTS[event.type](state, event) || state;
};

const ExportProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ExportReducer, INITIAL_STATE);

  const handleUpdateItems = (hssId) => {
    handleClearErrors();

    let itemsArray = state.exportItems;
    let recordIsSelected = itemsArray.some((item) => item === hssId);

    if (!recordIsSelected) {
      itemsArray.push(hssId);
    } else {
      itemsArray = itemsArray.filter((item) => item !== hssId);
    }
    ExportService._exportItems(itemsArray);
    dispatch({ type: EVENT_TYPES.EXPORT_UPDATE, payload: { itemsArray } });
  };
  const handleSelectAll = (isSelectAll) => {
    dispatch({ type: EVENT_TYPES.EXPORT_UPDATE_SELECT_ALL, payload: { isSelectAll: !isSelectAll } });
  };
  const handleClearAll = (isSelectAll) => {
    ExportService._exportItems([]);
    dispatch({ type: EVENT_TYPES.EXPORT_UPDATE_CLEAR_ALL, payload: { exportSuccess: false } });
  };
  const handleExport = (request) => {
    if (request.exportAll) {
      if (!isWildcardSearch()) {
        request = {
          ...request,
          exportAll: false,
          size: 10000,
        };
      }
    }

    ExportService.export(request)
      .then((data) => {
        dispatch({ type: EVENT_TYPES.EXPORT_SUCCESS, payload: { exportData: data } });
        setTimeout(() => {
          handleClearAll();
        }, "2000");
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.EXPORT_ERROR,
          payload: {
            exportError: message,
          },
        });
      });
  };

  const handleError = (errorMsg) => {
    dispatch({
      type: EVENT_TYPES.EXPORT_ERROR,
      payload: {
        exportError: errorMsg,
      },
    });
  };
  const handleClearErrors = () => {
    dispatch({ type: EVENT_TYPES.EXPORT_CLEAR_ERRORS });
  };

  const events = {
    onUpdateItems: handleUpdateItems,
    onSelectAll: handleSelectAll,
    onClearAll: handleClearAll,
    onError: handleError,
    onExport: handleExport,
    onClearErrors: handleClearErrors,
  };

  return (
    <ExportState.Provider value={state}>
      <ExportDispatch.Provider value={events}>{children}</ExportDispatch.Provider>
    </ExportState.Provider>
  );
};

const useExportState = () => {
  const context = useContext(ExportState);

  if (context === undefined) {
    throw new Error("useExportState must be used within an ExportProvider");
  }

  return context;
};

const useExportDispatch = () => {
  const context = useContext(ExportDispatch);

  if (context === undefined) {
    throw new Error("useExportDispatch must be used within an ExportProvider");
  }

  return context;
};

export { ExportProvider, useExportState, useExportDispatch };
