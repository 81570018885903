import React, { createContext, useContext, useReducer } from "react";
import { ManageAlertsService, AlertsService } from "../services";
import { useAlertsState, useAlertsDispatch } from "./alertsProvider";

const ManageAlertsState = createContext();
const ManageAlertsDispatch = createContext();
const EVENT_TYPES = {
  UPDATE: "update",
  INITIAL_UPDATE: "initial_update",
  NEW_ALERTS_LOADING: "new_alerts_loading",
  NEW_ALERTS_SUCCESS: "new_alerts_success",
  SAVE_ALERTS_LOADING: "save_alerts_loading",
  SAVE_ALERTS_SUCCESS: "SAVE_alerts_SUCCESS",
  DELETE_ALERTS_LOADING: "delete_alerts_loading",
  DELETE_ALERTS_SUCCESS: "delete_alerts_success",
  CLEAR_MANAGE_ALERTS_ERRORS: "clear_manage_alerts_errors",
  MANAGE_ALERTS_ERROR: "manage_alerts_error",
  CLEAR_ALL: "clear_all",
};
const EVENTS = {
  [EVENT_TYPES.UPDATE]: (state, event) => {
    const { name, value } = event.payload;
    return {
      ...state,
      [name]: value,
    };
  },
  [EVENT_TYPES.INITIAL_UPDATE]: (state, event) => {
    const { values } = event.payload;
    return {
      ...state,
      recordSection: values.row.recordSection,
      fieldName: values.row.fieldName,
      fieldDescription: values.row.fieldDescription,
      sourceOfData: values.row.sourceOfData,
      alertsId: values.row.id,
    };
  },
  [EVENT_TYPES.NEW_ALERTS_LOADING]: (state) => {
    return {
      ...state,
      newAlertsSuccess: false,
      newAlertsLoading: true,
      manageAlertsError: false,
    };
  },
  [EVENT_TYPES.SAVE_ALERTS_LOADING]: (state) => {
    return {
      ...state,
      saveAlertsSuccess: false,
      newAlertsSuccess: false,
      saveAlertsLoading: true,
    };
  },
  [EVENT_TYPES.DELETE_ALERTS_LOADING]: (state, event) => {
    const { alertsId } = event.payload;
    return {
      ...state,
      deleteAlertsSuccess: false,
      deleteAlertsLoading: true,
      manageAlertsError: false,
      deleteAlertsId: alertsId,
    };
  },
  [EVENT_TYPES.DELETE_ALERTS_SUCCESS]: (state) => {
    return {
      ...state,
      deleteAlertsSuccess: true,
      deleteAlertsLoading: false,
      manageAlertsError: false,
      deleteAlertsId: null,
    };
  },
  [EVENT_TYPES.NEW_ALERTS_SUCCESS]: (state) => {
    return {
      ...state,
      newAlertsSuccess: true,
      newAlertsLoading: false,
      manageAlertsError: false,
    };
  },
  [EVENT_TYPES.SAVE_ALERTS_SUCCESS]: (state) => {
    return {
      ...state,
      saveAlertsSuccess: true,
      newAlertsSuccess: false,
      saveAlertsLoading: false,
    };
  },
  [EVENT_TYPES.MANAGE_ALERTS_ERROR]: (state, event) => {
    const { error } = event.payload;
    return {
      ...state,
      error,
      saveAlertsSuccess: false,
      newAlertsSuccess: false,
      saveAlertsLoading: false,
    };
  },
  [EVENT_TYPES.CLEAR_MANAGE_ALERTS_ERRORS]: (state) => {
    return {
      ...state,
      manageAlertsError: "",
      saveAlertsSuccess: false,
      newAlertsSuccess: false,
      saveAlertsLoading: false,
    };
  },
  [EVENT_TYPES.CLEAR_ALL]: (state) => {
    return {
      ...state,
      recordSection: "",
      fieldName: "",
      fieldDescription: "",
      sourceOfData: "",
      alertsId: "",
      manageAlertsError: "",
      saveAlertsSuccess: false,
      saveAlertsLoading: false,
      deleteAlertsLoading: false,
      deleteAlertsSuccess: false,
      deleteAlertsId: null,
      newAlertsLoading: false,
      newAlertsSuccess: false,
    };
  },
};

const INITIAL_STATE = {
  recordSection: "",
  fieldName: "",
  fieldDescription: "",
  sourceOfData: "",
  alertsId: "",
  manageAlertsError: "",
  saveAlertsSuccess: false,
  saveAlertsLoading: false,
  deleteAlertsLoading: false,
  deleteAlertsSuccess: false,
  deleteAlertsId: null,
  newAlertsLoading: false,
  newAlertsSuccess: false,
};

const ManageAlertsReducer = (state, event) => {
  return EVENTS[event.type](state, event) || state;
};

const ManageAlertsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ManageAlertsReducer, INITIAL_STATE);

  const handleUpdate = (event) => {
    handleClearErrors();
    const name = event.target.name;
    const value = event.target.value;

    dispatch({ type: EVENT_TYPES.UPDATE, payload: { name, value } });
  };
  const handleClearAll = (event) => {
    dispatch({ type: EVENT_TYPES.CLEAR_ALL });
  };

  const handleInitialUpdate = (values) => {
    handleClearErrors();
    dispatch({ type: EVENT_TYPES.INITIAL_UPDATE, payload: { values } });
  };

  const handleSaveExistingAlerts = () => {
    const { recordSection, fieldName, fieldDescription, sourceOfData, alertsId } = state;
    dispatch({ type: EVENT_TYPES.SAVE_ALERTS_LOADING });
    ManageAlertsService.updateGlossary(recordSection, fieldName, fieldDescription, sourceOfData, alertsId)
      .then(() => {
        dispatch({ type: EVENT_TYPES.SAVE_ALERTS_SUCCESS });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.MANAGE_ALERTS_ERROR,
          payload: {
            manageAlertsError: message,
          },
        });
      });
  };
  const handleAddAlerts = (alertsId) => {
    const { recordSection, fieldName, fieldDescription, sourceOfData } = state;
    dispatch({ type: EVENT_TYPES.NEW_ALERTS_LOADING });
    ManageAlertsService.saveGlossary(recordSection, fieldName, fieldDescription, sourceOfData)
      .then(() => {
        dispatch({ type: EVENT_TYPES.NEW_ALERTS_SUCCESS });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.MANAGE_ALERTS_ERROR,
          payload: {
            manageAlertsError: message,
          },
        });
      });
  };
  const handleDeleteAlerts = (alertsId) => {
    dispatch({ type: EVENT_TYPES.DELETE_ALERTS_LOADING, payload: { alertsId } });
    ManageAlertsService.deleteGlossary(alertsId)
      .then(() => {
        dispatch({ type: EVENT_TYPES.DELETE_ALERTS_SUCCESS });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.MANAGE_ALERTS_ERROR,
          payload: {
            manageAlertsError: message,
          },
        });
      });
  };
  const handleError = (errorMsg) => {
    dispatch({
      type: EVENT_TYPES.MANAGE_ALERTS_ERROR,
      payload: {
        manageAlertsError: errorMsg,
      },
    });
  };
  const handleClearErrors = () => {
    dispatch({ type: EVENT_TYPES.CLEAR_MANAGE_ALERTS_ERRORS });
  };

  const events = {
    onUpdate: handleUpdate,
    onInitialUpdate: handleInitialUpdate,
    onError: handleError,
    onSaveExistingAlerts: handleSaveExistingAlerts,
    onAddGAlerts: handleAddAlerts,
    onDeleteAlerts: handleDeleteAlerts,
    onClearErrors: handleClearErrors,
    onClearAll: handleClearAll,
  };

  return (
    <ManageAlertsState.Provider value={state}>
      <ManageAlertsDispatch.Provider value={events}>{children}</ManageAlertsDispatch.Provider>
    </ManageAlertsState.Provider>
  );
};

const useManageAlertsState = () => {
  const context = useContext(ManageAlertsState);

  if (context === undefined) {
    throw new Error("useManageAlertsState must be used within an ManageAlertsProvider");
  }

  return context;
};

const useManageAlertsDispatch = () => {
  const context = useContext(ManageAlertsDispatch);

  if (context === undefined) {
    throw new Error("useManageAlertsDispatch must be used within an ManageAlertsProvider");
  }

  return context;
};

export { ManageAlertsProvider, useManageAlertsState, useManageAlertsDispatch };
