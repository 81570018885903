import axios from "axios";
import { saveAs } from "file-saver";
import { fetchWithTimeout } from "./serviceHelpers";
import AuthService from "./authService";

const ExportService = {
  _url: `${process.env.REACT_APP_API_URL || ""}/api`,

  _exportItems(itemsArray) {
    if (typeof itemsArray !== "undefined") {
      localStorage.setItem("exportItems", JSON.stringify(itemsArray));
      return;
    }
    return localStorage.getItem("exportItems");
  },
  async export(request) {
    let newRequest = {
      ...request,
    };
    try {
      let response = await fetch(`${this._url}/export`, {
        method: "POST",
        body: JSON.stringify(newRequest),
        headers: {
          "content-type": "application/vnd.restful+json",
          authorization: `Bearer ${AuthService._token()}`,
          Accept: "text/csv",
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.blob();
        throw new Error(data.responseCode);
      }
      let data = await response.blob();
      let blobData = await saveAs(data, `export.${request.exportFormat}`);
      localStorage.removeItem("exportItems");
      return blobData;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },
};

export default ExportService;
